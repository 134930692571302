// If you want to override variables do it here
@import "variables";

// Import CoreUI styles
@import "~@coreui/coreui/scss/coreui.scss";

// Some temp fixes
@import "fixes";

// If you want to add something do it here
@import "custom";

$primary-color: #141ad0;
$primary-hover: #1119b2;
$primary-text: #3c4b64;

#header-avatar-icon {
  border: 1px solid #888;
  border-radius: 25px;
}

a {
  color: $primary-color;
}

.btn-primary,
.btn-primary:active,
.page-item.active .page-link {
  background-color: $primary-color !important;
  border: $primary-color !important;
}

.btn-primary:hover {
  background-color: $primary-hover !important;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: $primary-color !important;
}

.page-link,
.btn-link {
  color: $primary-color;
}

.btn-link:hover {
  color: $primary-hover !important;
}

.btn-outline-primary {
  color: #fff;
  background-color: $primary-color !important;
  border: 1px solid $primary-color !important;
}

.btn-outline-primary:hover,
.btn-outline-primary:not(:disabled):not(.disabled):active {
  background-color: $primary-hover !important;
  border: 1px solid $primary-hover !important;
}

.card-header-with-action {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.btn-ghost-primary {
  color: $primary-text;
}

.btn-ghost-primary:not(:disabled):not(.disabled):active,
.btn-ghost-primary:hover {
  background-color: $primary-hover !important;
  border-color: $primary-hover !important;
}

.table-button {
  z-index: 1000 !important;
}

.other-message-container {
  text-align: right;
}

.my-message {
  background-color: $primary-color;
  color: white !important;
}

.c-sidebar-brand-full {
  background: white;
  padding: 0.3em;
  border-radius: 3px;
}

.login-logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.c-sidebar.c-sidebar-minimized .c-sidebar-nav-item:hover > .c-sidebar-nav-link,
.c-sidebar.c-sidebar-minimized
  .c-sidebar-nav-item:hover
  > .c-sidebar-nav-dropdown-toggle {
  background-color: $primary-color;
}

.form-image {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 4px;
}

.table-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 4px;
}

.inputRowButton {
  height: calc(1.5em + 0.75rem + 2px);
}

.bioAddLabel {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// .table thead th, .table td {
//   min-width: 120px !important;
// }


@media (hover: hover), not all {
  .c-sidebar .c-sidebar-nav-link:hover,
  .c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
    background: $primary-color;
  }
}

.required::after {
  content: ' *';
  color: red;
}

.rowRedTable {
  background-color: #f4cccc;
}